import { Component } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { Box } from '@mui/material';
import { ReduxState } from '../../reducers';
import UpdateTrialPeriodComponent from './TrialPeriod/UpdateTrialPeriodComponent';
import { getCurrentTrialPeriod, updateTrialPeriod } from '../../actions/misc';

export interface ComponentState {}

interface ComponentProps {}

interface ReduxProps {
    loadingTrialPeriod?: boolean;
    trialPeriod?: number;
}

interface ReduxActionProps {
    getCurrentTrialPeriod: () => void;
    updateTrialPeriod: (trialPeriod: number) => void;
}

type Props = ComponentProps & ReduxProps & ReduxActionProps;

class MiscComponent extends Component<Props, ComponentState> {
    state: ComponentState = {};

    componentDidMount() {
        this.props.getCurrentTrialPeriod();
    }

    render() {
        return (
            <Box>
                <UpdateTrialPeriodComponent loadingTrialPeriod={this.props.loadingTrialPeriod} currentTrialPeriod={this.props.trialPeriod ?? 0} updateTrialPeriod={this.props.updateTrialPeriod} />
            </Box>
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ReduxState> = ({ misc }: ReduxState) => {
    const { loadingTrialPeriod, trialPeriod } = misc;
    
    return {
        loadingTrialPeriod,
        trialPeriod,

    };
};

export default connect<ReduxProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { getCurrentTrialPeriod, updateTrialPeriod })(MiscComponent);
